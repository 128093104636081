import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Cookies from 'js-cookie'
import axios from 'axios'

import colors from '@styles/js/colors'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const PORTAL_ID = 5217373
const FORM_ID = '74f60aee-d0a7-43b3-8f65-eee22d208725'

const schema = Yup.object({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email is required')
})

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

const SubscribeNewsletter = styled(({ className }) => {
  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })
  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])
  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 10000)
  }
  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    showMessage('Subscribing email...')

    const { email } = model
    const isBrowser = typeof window !== 'undefined'
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

    const body = {
      fields: [
        {
          name: 'email',
          value: email
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName
      }
    }

    axios
      .post(postUrl, body)
      .then(res => {
        if (res.status === 200) {
          resetForm()
          //showMessage('You subscribe successfully!')
          showMessage(
            '<b>THANK YOU.</b> YOU WILL RECEIVE AN EMAIL CONFIRMATION FROM CODISTRY.'
          )
        }
      })
      .catch(err => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        email: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors
      }) => (
        <Form
          className={`${className} px-4 py-5 p-md-5 bg-green-light`}
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="form-content">
            <h2 className="font-weight-light mb-3 mb-md-5">
              Get awesome content in your inbox every week
            </h2>
            <div className="d-sm-flex">
              <Form.Control
                type="email"
                placeholder="Add your email address"
                id="hubspot-form-email"
                name="email"
                className="email form-control mb-3 mb-md-0"
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email}
                value={values.email}
              />
              <button className="submit" type="submit">
                Subscribe
              </button>
            </div>
            {errors.email && touched.email && (
              <p className="invalid-helper">{errors.email}</p>
            )}
            {message.show && (
              <div className="form-group">
                <br />
                <Alert variant={message.type}>
                  <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                </Alert>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
})`
  .alert-info {
    background: transparent;
    border: none;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px;
  }

  .invalid-helper {
    color: #e10506;
    font-size: 16px;
    line-height: 24px;
    padding-left: 24px;
    margin-top: 16px;
  }

  .form-content {
    margin: auto;
    max-width: 850px;
  }

  @media (min-width: 768px) {
    width: auto;
  }

  .submit {
    background-color: ${colors.saffron};
    border: 0;
    color: ${colors.black};
    font-size: 18px !important;
    height: 58px !important;
    padding: 0.85rem 1rem !important;
    width: 100%;

    &:hover {
      background-color: ${colors.gamboge};
    }

    @media (min-width: 768px) {
      width: auto;
    }
  }
`

export default SubscribeNewsletter
