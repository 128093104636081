import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

// helpers
import { getCategoryName } from '@helpers'

// colors
import colors from '@styles/js/colors'

const Article = styled(({ data, className }) => {
  const { id, featuredImage, categories, date, title, uri } = data
  const { imageFile, altText } = featuredImage || {}
  const postCategories = getCategoryName(categories)

  const formattedPublishingDay = dayjs(date).format('MMM DD, YYYY')

  return (
    <div className={`${className}`}>
      <Link
        className="card d-block text-decoration-none w-100 text-black"
        to={`/blog${uri}`}
        state={{ id: id }}
      >
        {imageFile && (
          <div className="bg-img position-relative">
            <Img
              fluid={imageFile.childImageSharp.fluid}
              className="h-100 position-absolute w-100"
              alt={altText ? altText : title}
            />
          </div>
        )}
        <div className="content bg-white px-3 py-4 w-100">
          <div className="d-flex align-items-center justify-content-between article-info">
            <span className="text position-relative">
              {postCategories.join(', ')}
            </span>
            <span className="date">{formattedPublishingDay}</span>
          </div>
          <h6 className="text-black mb-0">{title}</h6>
        </div>
      </Link>
    </div>
  )
})`
  .card {
    height: 540px !important;

    @media (min-width: 768px) {
      height: 452px !important;
    }

    @media (min-width: 992px) {
      height: 432px !important;
    }

    @media (min-width: 1200px) {
      height: 472px !important;
    }

    .bg-img {
      height: 400px;

      @media (min-width: 768px) {
        height: 352px !important;
      }

      @media (min-width: 992px) {
        height: 332px !important;
      }

      @media (min-width: 1200px) {
        height: 372px !important;
      }
    }

    .content {
      max-width: 95%;
      min-height: 130px;
      transform: translateY(-50%);

      .article-info{
        margin-bottom: 20px;

        @media (min-width: 768px) {
          margin-bottom: 18px;
        }
      }

      .text {
        &:after {
          background-color: ${colors.saffron};
          content: '';
          height: 1px;
          position: absolute;
          margin-left: 10px;
          top: 12px;
          width: 30px;
        }
      }

      .date {
        font-size: 12px;
      }

      h6 {
        font-size: 18px;
      }
    }
  }
`

Article.propTypes = {
  data: object.isRequired
}

Article.defaultProps = {
  data: {}
}

export default Article
