import React, { useState, useRef, useEffect } from 'react'
import { object } from 'prop-types'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import colors from '@styles/js/colors'
import BackgroundImage from 'gatsby-background-image'

// modules
import { useWindowDimensions } from '@modules/useWindowDimensions'

//helpers
import { getCategoryName } from '@helpers'

const XSHEIGHT = 400
const SMHEIGHT = 650
const BOOTSTRAPGUTTER = 15

const Hero = styled(({ data, className }) => {
  const { title } = data

  const { categories } = data || ''
  const postCategories = getCategoryName(categories)

  const uri = data.uri
  const id = data.id
  const { width: windowSize } = useWindowDimensions()

  const refHero = useRef(null)
  const refPrimary = useRef(null)
  const refSecondary = useRef(null)
  const refContent = useRef(null)

  const [heroWrapperWidth, setHeroWrapperWidth] = useState({})
  const [mainWrapperWidth, setMainWrapperWidth] = useState({})
  const [secondaryWrapperWidth, setSecondaryWrapperWidth] = useState({})
  const [refContentHeight, setRefContentHeight] = useState({})

  const [mainGap, setMainGap] = useState({})
  const [secondaryGap, setSecondaryGap] = useState({})

  useEffect(() => {
    const heroCurrentWidth = refHero.current.clientWidth

    const mainPosition = (heroWrapperWidth - refPrimary.current.clientWidth) / 2
    const mainCurrentWidth = refPrimary.current.clientWidth + mainPosition

    const secondaryPosition =
      (heroWrapperWidth - refSecondary.current.clientWidth) / 2
    const secondaryCurrentWidth =
      refSecondary.current.clientWidth + secondaryPosition

    const refContentCurrentHeight = refContent.current.clientHeight

    setHeroWrapperWidth(heroCurrentWidth)

    setMainWrapperWidth(mainCurrentWidth)
    setMainGap(mainPosition)

    setSecondaryWrapperWidth(secondaryCurrentWidth)
    setSecondaryGap(secondaryPosition)

    setRefContentHeight(refContentCurrentHeight)
  }, [
    refPrimary,
    mainWrapperWidth,
    refSecondary,
    secondaryWrapperWidth,
    refContent,
    refContentHeight,
    heroWrapperWidth,
    windowSize
  ])

  return (
    <div className={`${className} mb-5 position-relative`} ref={refHero}>
      <Link className="hero-link" to={`/blog${uri}`} state={{ id: id }}>
        <Container
          ref={refPrimary}
          className="container-primary p-0"
          css={css`
            height: ${XSHEIGHT + refContentHeight / 2}px;

            @media (min-width: 768px) {
              height: ${SMHEIGHT}px;
            }
          `}
        >
          <Row className="m-0">
            <Col className="p-0">
              <div
                className="position-absolute"
                css={css`
                  height: ${XSHEIGHT + refContentHeight / 2}px;
                  width: ${mainWrapperWidth}px;

                  @media (min-width: 768px) {
                    height: ${SMHEIGHT}px;
                    right: -${mainGap}px;
                    width: ${mainWrapperWidth - BOOTSTRAPGUTTER}px;
                  }
                `}
              >
                <BackgroundImage
                  Tag="section"
                  className="background-img"
                  fluid={
                    data.postElements.postHeroImage.imageFile.childImageSharp
                      .fluid
                  }
                  alt={
                    data.postElements.postHeroImage.altText
                      ? data.postElements.postHeroImage.altText
                      : title
                  }
                  backgroundColor={`#040e18`}
                />

                <div
                  className="position-absolute w-100"
                  css={css`
                    top: 0;
                    height: ${XSHEIGHT + refContentHeight / 2}px;

                    @media (min-width: 768px) {
                      height: ${SMHEIGHT}px;
                    }
                  `}
                >
                  <Container className="h-100 pl-md-0 m-md-0">
                    <Row className="h-100 m-0">
                      <Col className="p-0">
                        <div ref={refSecondary} className="h-100">
                          <div
                            className="content bg-white position-absolute"
                            ref={refContent}
                            css={css`
                              bottom: ${refContentHeight / 2}px;
                              right: -${secondaryGap}px;
                              width: ${secondaryWrapperWidth}px;

                              @media (min-width: 768px) {
                                bottom: 0;
                              }
                            `}
                          >
                            <p className="position-relative d-inline-block text-capitalize mb-3">
                              {postCategories.join(', ')}
                            </p>
                            <h3 className="mb-0">{title}</h3>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Link>
    </div>
  )
})`
  .container-primary {
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .background-img {
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    width: 100%;

    @media (min-width: 768px) {
      height: 650px;
    }
  }

  .content {
    padding: 30px 40px;
    transform: translateY(50%);
    z-index: 1;

    @media (min-width: 768px) {
      width: 80%;
      right: inherit;
      left: 0;
      transform: translateY(0%);
    }

    h3 {
      font-size: 28px;
      font-weight: 300;
      line-height: 38px;

      @media (min-width: 768px) {
        font-size: 58px;
        line-height: 68px;
      }
    }

    p {
      &:after {
        background-color: ${colors.saffron};
        content: '';
        height: 1px;
        position: absolute;
        right: -45px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
      }
    }
  }

  .hero-link {
    .content {
      h3 {
        color: ${colors.black};
      }
      p {
        color: ${colors.black};
      }
    }
  }
`

Hero.propTypes = {
  data: object.isRequired,
  fluid: object.isRequired
}

Hero.defaultProps = {
  data: {},
  fluid: {}
}

export default Hero
