import React from 'react'
import ButtonPrev from '@components/ButtonPrev'
import ButtonNext from '@components/ButtonNext'

const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: <ButtonPrev />,
  nextArrow: <ButtonNext />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        arrows: true,
        dots: false
      }
    }
  ]
}

export default sliderSettings