import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Blog from '@compositions/Blog'

// components
import SEO from '@components/SEO'

import { blogContent } from '@compositions/Blog/modules/graphql'

const BlogPage = () => {
  const data = blogContent() || {}

  const seo = data.page?.AllPageElements.pageelements[0]
  const title = seo?.title || ''
  const description = seo?.description || ''

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Blog />
    </Layout>
  )
}

export default BlogPage
