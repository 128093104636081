import React from 'react'
import { arrayOf, object, string } from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import Article from '@components/Article'

const ArticlesSuggested = ({ selected, data, className }) => {
  return (
    <section className={`${className}`}>
      <Container>
        <Row>
          {data.map((item, index) => {
            let tags = []
            tags = item.categories.nodes.map(cat => {
              return cat.slug
            })
            return tags.includes(selected) || ['all'].includes(selected) ? (
              <Col md={6} lg={4} key={index}>
                <Article data={item} />
              </Col>
            ) : null
          })}
        </Row>
      </Container>
    </section>
  )
}

ArticlesSuggested.propTypes = {
  data: arrayOf(object).isRequired,
  selected: string,
  className: string
}

ArticlesSuggested.defaultProps = {
  data: {},
  selected: 'all',
  className: ''
}

export default ArticlesSuggested
