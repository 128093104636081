import React, { useState, useEffect, useRef } from 'react'
import { arrayOf, object, string } from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Slider from 'react-slick'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import colors from '@styles/js/colors'

// components
import Article from '@components/Article'

// modules
import { useWindowDimensions } from '@modules/useWindowDimensions'
import sliderSettings from './modules/constants'

const EXTRAWIDTH = 270

const Articles = styled(({ selected, data, className }) => {
  const { width: windowSize } = useWindowDimensions()

  const refArticles = useRef(null)
  const refMain = useRef(null)

  const [articlesWrapperWidth, setArticlesWrapperWidth] = useState({})
  const [mainWrapperWidth, setMainWrapperWidth] = useState({})
  const [mainGap, setMainGap] = useState({})

  useEffect(() => {
    const articlesCurrentWidth = refArticles.current.clientWidth

    const mainPosition =
      (articlesWrapperWidth - refMain.current.clientWidth) / 2
    const mainCurrentWidth = refMain.current.clientWidth + mainPosition

    setArticlesWrapperWidth(articlesCurrentWidth)

    setMainWrapperWidth(mainCurrentWidth)
    setMainGap(mainPosition)
  }, [refMain, articlesWrapperWidth, windowSize])

  return (
    <section className={`${className}`} ref={refArticles}>
      <Container>
        <Row>
          <Col>
            <div ref={refMain}>
              <div
                className="overflow-hidden mb-5 mb-md-0"
                css={css`
                  width: ${mainWrapperWidth}px;

                  @media (min-width: 768px) {
                    right: -${mainGap}px;
                    width: ${mainWrapperWidth}px;
                  }
                `}
              >
                <div
                  css={css`
                    width: ${mainWrapperWidth + EXTRAWIDTH}px;

                    @media (min-width: 768px) {
                      width: ${mainWrapperWidth + EXTRAWIDTH}px;
                    }

                    @media (min-width: 992px) {
                      width: ${mainWrapperWidth + EXTRAWIDTH}px;
                    }
                  `}
                >
                  <Slider {...sliderSettings}>
                    {data.map((item, index) => {
                      let tags = []
                      tags = item.categories.nodes.map(cat => {
                        return cat.slug
                      })
                      return tags.includes(selected) ||
                        ['all'].includes(selected) ? (
                        <Article data={item} key={index} />
                      ) : null
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
})`
  .slick-list {
    .slick-slide {
      margin-right: 1rem !important;

      > div {
        background: white !important;
        width: 100%;
      }
    }
  }

  .content {
    transition: all 1s ease;

    .date {
      color: ${colors.grey};
    }
  }

  .slick-dots {
    margin-top: 0 !important;
    margin-bottom: 3rem;
  }

  .slick-arrow {
    bottom: 4px;
    transform: translateY(0%) !important;
  }
`

Articles.propTypes = {
  data: arrayOf(object).isRequired,
  selected: string
}

Articles.defaultProps = {
  data: {},
  selected: 'all'
}

export default Articles
