import { useStaticQuery, graphql } from 'gatsby'

export const blogContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "340", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
                keyword
              }
            }
          }
        }
        featured: posts(first: 6, where: { tagId: "17" }) {
          nodes {
            id
            title
            postId
            uri
            date
            featuredImage {
              altText
              sourceUrl
              mediaItemId
              altText
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 410, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              nodes {
                id
                slug
                name
                categoryId
              }
            }
            postElements {
              postHeroImage {
                altText
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1455, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        latest: posts(first: 6, where: {tagNotIn: "17"}) {
          nodes {
            id
            title
            postId
            uri
            date
            featuredImage {
              altText
              sourceUrl
              mediaItemId
              altText
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              nodes {
                id
                slug
                name
                categoryId
              }
            }
            postElements {
              postHeroImage {
                altText
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1455, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        categories(where: {hideEmpty: true}) {
          nodes {
            name
            count
          }
        }
        themeSettings {
          settingFields {
            project {
              submitbutton
              title
              image {
                mobile {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                tablet {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                desktop {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql
}
