import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '@components/SEO'
import Hr from '@components/Hr'
import Background from './components/Background'
import Head from './components/Head'
import Hero from './components/Hero'
import ArticlesSuggested from './components/ArticlesSuggested'
import ArticlesLatest from './components/ArticlesLatest'
import SubscribeNewsletter from './components/SubscribeNewsletter'
import ProjectForm from '@components/ProjectForm'

// hooks
import { blogContent } from './modules/graphql'

const Blog = () => {
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState('all')
  // eslint-disable-next-line no-unused-vars
  const [showFilters, setShowFilters] = useState(false)

  const [featuredBodyPosts, setFeaturedBodyPosts] = useState(false)

  const data = blogContent() || []

  const { categories, featured, latest } = data || ''

  // Categories data
  const { nodes: categoryNodes } = categories || ''
  const categoryFilters = categoryNodes.length > 0 ? categoryNodes : []

  let filters = ['all']

  for (var key in categoryFilters) {
    filters.push(categoryFilters[key].name.toLowerCase())
  }

  // Featured articles data
  const { nodes: featuredNodes } = featured || ''
  const featuredArticles = featuredNodes.length > 0 ? featuredNodes : []

  const heroPost = featuredArticles.length > 0 ? featuredArticles[0] : null

  // Featured articles data
  const { nodes: latestNodes } = latest || ''
  const latestArticles = latestNodes.length > 0 ? latestNodes : []

  useEffect(() => {
    setFeaturedBodyPosts(
      featuredArticles.slice(1).length > 0 ? featuredArticles.slice(1) : null
    )
  }, [featuredArticles])

  const handleClick = key => {
    setSelected(key)
  }

  const handleShowFilters = () => {
    setShowFilters(!showFilters)
  }

  const seoData = data.page?.AllPageElements.pageelements[0]

  const projectFormData = data.themeSettings.settingFields.project

  // const posts =
  //   selected === 'all'
  //     ? allWpPosts
  //     : // eslint-disable-next-line array-callback-return
  //       allWpPosts.filter(post => {
  //         let status = 0
  //         // eslint-disable-next-line array-callback-return
  //         post.categories.nodes.map(cat => {
  //           if (cat.slug === selected) {
  //             status++
  //           }
  //         })
  //         if (status > 0) {
  //           return post
  //         }
  //       })

  return (
    <>
      {seoData ? (
        <SEO
          title={seoData.title}
          description={seoData.description}
          keyword={seoData.keyword}
        />
      ) : null}
      <Background>
        <Head
          handleShowFilters={handleShowFilters}
          showFilters={showFilters}
          filters={filters}
          selected={selected}
          handleClick={handleClick}
        />

        {heroPost && <Hero data={heroPost} />}

        {featuredBodyPosts ? (
          <>
            <div className="mb-5 d-inline-block w-100">
              <Container>
                <Row>
                  <Col>
                    <h2 className="mb-3">Featured Articles</h2>
                    <Hr />
                  </Col>
                </Row>
              </Container>
            </div>
            <ArticlesSuggested selected={selected} data={featuredBodyPosts} />
          </>
        ) : (
          ''
        )}

        {latestArticles ? (
          <>
            <div className="mb-5 d-inline-block w-100">
              <Container>
                <Row>
                  <Col>
                    <h2 className="mb-3">Latest Articles</h2>
                    <Hr />
                  </Col>
                </Row>
              </Container>
            </div>
            <ArticlesLatest selected={selected} data={latestArticles} />
          </>
        ) : (
          ''
        )}
      </Background>

      <SubscribeNewsletter />
      <div className="blog-project-form-wrapper">
        <ProjectForm data={projectFormData} className="mt-5" />
      </div>
    </>
  )
}

export default Blog
