import React from 'react'
import { array, bool, func, string } from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

// components
// import ButtonOutline from './components/ButtonOutline'
// import Filters from './components/Filters'

// styles
import colors from '@styles/js/colors'

const Head = styled(
  ({
    handleShowFilters,
    showFilters,
    filters,
    selected,
    handleClick,
    className
  }) => {
    return (
      <div className={`${className}`}>
        <div className="my-5 d-inline-block w-100">
          <Container>
            <Row className="align-items-center">
              <Col xs={4} sm={6}>
                <h1 className="mb-0 text-white">Blog</h1>
              </Col>
              {/* <Col xs={8} sm={6}>
                <div className="d-flex justify-content-end">
                  <ButtonOutline
                    onClick={handleShowFilters}
                    className={`${showFilters ? 'active' : ''}`}
                    showed={showFilters}
                  />
                  {showFilters && (
                    <Filters
                      data={filters}
                      selected={selected}
                      onClick={handleClick}
                    />
                  )}
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    )
  }
)`
  background-color: ${colors.saffron};

  @media (min-width: 768px) {
    background-color: transparent;
  }

  h1 {
    font-size: 48px;
    line-height: 58px;

    @media (min-width: 768px) {
      font-size: 64px;
      line-height: 74px;
    }
  }
`

Head.propTypes = {
  filters: array.isRequired,
  handleShowFilters: func,
  showFilters: bool,
  selected: string,
  handleClick: func
}

Head.defaultProps = {
  handleShowFilters: () => null,
  showFilters: false,
  selected: 'all',
  handleClick: () => null
}

export default Head
