import React from 'react'
import styled from '@emotion/styled'

const Background = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  @media (min-width: 768px) {
    background-image: url('./../blog.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
`

export default Background
